import apiSlice from '../apiSlice';

const assetCollectionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAssetPresentations: builder.mutation<
            { data: AssetPresentation[] } & Pagination,
            GetAssetPresentationsRequest
        >({
            query: ({ projectId, options }) => ({
                url: `developer/projects/${projectId}/assetPresentations${options}`,
                method: 'GET',
            }),
        }),
        getAssetPresentation: builder.query<AssetPresentation, GetAssetPresentationRequest>({
            query: ({ projectId, presentationId }) => ({
                url: `developer/projects/${projectId}/assetPresentations/${presentationId}`,
                method: 'GET',
            }),
        }),
        storeAssetPresentation: builder.mutation<AssetPresentation, StoreAssetPresentationRequest>({
            query: ({
                projectId,
                assets,
                name,
                shared_password,
                is_asset_comments_shared,
                is_asset_versions_shared,
                is_asset_materials_shared,
                is_asset_downloadable_shared,
            }) => ({
                url: `developer/projects/${projectId}/assetPresentations`,
                method: 'POST',
                body: {
                    assets,
                    name,
                    shared_password,
                    is_asset_comments_shared,
                    is_asset_versions_shared,
                    is_asset_materials_shared,
                    is_asset_downloadable_shared,
                },
            }),
            invalidatesTags: ['asset-collections'],
        }),
        updateAssetPresentation: builder.mutation<AssetPresentation, UpdateAssetPresentationRequest>({
            query: ({
                projectId,
                presentationId,
                name,
                shared_password,
                is_asset_comments_shared,
                is_asset_versions_shared,
                is_asset_materials_shared,
                is_asset_downloadable_shared,
                assets,
            }) => ({
                url: `developer/projects/${projectId}/assetPresentations/${presentationId}`,
                method: 'PUT',
                body: {
                    name,
                    shared_password,
                    is_asset_comments_shared,
                    is_asset_versions_shared,
                    is_asset_materials_shared,
                    is_asset_downloadable_shared,
                    assets,
                },
            }),
        }),
        deleteAssetPresentation: builder.mutation<null, DeleteAssetPresentationRequest>({
            query: ({ projectId, presentationId }) => ({
                url: `developer/projects/${projectId}/assetPresentations/${presentationId}`,
                method: 'DELETE',
            }),
        }),
        getSharedAssetPresentation: builder.mutation<AssetPresentation, GetSharedAssetPresentationRequest>({
            query: ({ code }) => ({
                url: `shared/assetPresentations/${code}`,
                method: 'GET',
            }),
        }),
        sharedAssetPresentationGrant: builder.mutation<SharedResourceGrant, SharedAssetPresentationGrantRequest>({
            query: ({ code, password, name }) => ({
                url: `shared/assetPresentations/${code}/grant`,
                method: 'POST',
                body: { password, name },
            }),
        }),
        sharedAssetPresentationViewed: builder.mutation<null, SharedAssetPresentationViewedRequest>({
            query: ({ code }) => ({
                url: `shared/assetPresentations/${code}/viewed`,
                method: 'POST',
            }),
        }),
        getSharedPresentationMembersForMentions: builder.query<MentionDict[], GetSharedAssetPresentationMembersRequest>(
            {
                query: ({ code }) => ({
                    url: `shared/assetPresentations/${code}/membersToMention`,
                    method: 'GET',
                }),
                providesTags: ['project-members-to-mention'],
                transformResponse: (response: User[]) =>
                    response.map((user): MentionDict => ({ id: user.id, display: user.name })),
            }
        ),
    }),
});

export default assetCollectionApi;

export const {
    useGetAssetPresentationsMutation,
    useGetAssetPresentationQuery,
    useStoreAssetPresentationMutation,
    useUpdateAssetPresentationMutation,
    useDeleteAssetPresentationMutation,
    useGetSharedAssetPresentationMutation,
    useSharedAssetPresentationGrantMutation,
    useGetSharedPresentationMembersForMentionsQuery,
    useSharedAssetPresentationViewedMutation,
} = assetCollectionApi;
