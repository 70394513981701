import apiSlice from '../apiSlice';

const assetCollectionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRecentAssetCollections: builder.query<GetAssetCollectionsResponse, GetAssetCollectionsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections?sort=-updated&limit=15&include=user,assetsCount,mediaCount`,
                method: 'GET',
            }),
        }),
        getRecentTrashedAssetCollections: builder.query<GetAssetCollectionsResponse, GetAssetCollectionsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections?filter[trashed]=only&sort=-updated&limit=15&include=user`,
                method: 'GET',
            }),
            providesTags: ['recent-trashed-folders'],
        }),
        getAssetCollections: builder.mutation<GetAssetCollectionsResponse, GetAssetCollectionsRequest>({
            query: ({ options, projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections${options}`,
                method: 'GET',
            }),
        }),
        getAllAssetCollections: builder.query<AssetCollection[], GetAllAssetCollectionsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections?limit=-1`,
                method: 'GET',
            }),
            providesTags: ['asset-collections'],
        }),
        getAvailableAssetCollections: builder.mutation<AssetCollection[], GetAllAssetCollectionsRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections?limit=-1`,
                method: 'GET',
            }),
        }),
        getAssetCollection: builder.query<AssetCollection, GetAssetCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}`,
                method: 'GET',
            }),
        }),
        storeAssetCollection: builder.mutation<AssetCollection, StoreAssetCollectionRequest>({
            query: ({ projectId, name, asset_id, media_id }) => ({
                url: `developer/projects/${projectId}/assetCollections`,
                method: 'POST',
                body: { name, asset_id, media_id },
            }),
            invalidatesTags: ['asset-collections'],
        }),
        updateAssetCollection: builder.mutation<AssetCollection, UpdateAssetCollectionRequest>({
            query: ({
                projectId,
                collectionId,
                name,
                is_published,
                shared_password,
                is_shared,
                is_asset_comments_shared,
                is_asset_versions_shared,
                is_asset_materials_shared,
                is_asset_downloadable_shared,
                remake_shareable_code,
                assets,
                thumbnail_assets,
                media,
            }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}`,
                method: 'PUT',
                body: {
                    name,
                    is_published,
                    is_shared,
                    shared_password,
                    is_asset_comments_shared,
                    is_asset_versions_shared,
                    is_asset_materials_shared,
                    is_asset_downloadable_shared,
                    remake_shareable_code,
                    assets,
                    thumbnail_assets,
                    media,
                },
            }),
        }),
        storeAssetCollectionThumbnail: builder.mutation<AssetCollection, StoreAssetCollectionThumbnailRequest>({
            query: ({ projectId, collectionId, form }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}/thumbnail`,
                method: 'POST',
                body: form,
            }),
        }),
        archiveAssetCollection: builder.mutation<null, ArchiveAssetCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['asset-collections'],
        }),
        archiveAssetCollections: builder.mutation<null, ArchiveAssetCollectionsRequest>({
            query: ({ projectId, collections }) => ({
                url: `developer/projects/${projectId}/assetCollections/bulk/archive`,
                method: 'PUT',
                body: { collections },
            }),
            invalidatesTags: ['asset-collections'],
        }),
        purgeAssetCollection: builder.mutation<null, PurgeAssetCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}/purge`,
                method: 'DELETE',
            }),
        }),
        restoreAssetCollection: builder.mutation<AssetCollection, RestoreAssetCollectionRequest>({
            query: ({ projectId, collectionId }) => ({
                url: `developer/projects/${projectId}/assetCollections/${collectionId}/restore`,
                method: 'PUT',
            }),
            invalidatesTags: ['asset-collections'],
        }),
        restoreAssetCollections: builder.mutation<null, RestoreAssetCollectionsRequest>({
            query: ({ projectId, collections }) => ({
                url: `developer/projects/${projectId}/assetCollections/bulk/restore`,
                method: 'PUT',
                body: { collections },
            }),
            invalidatesTags: ['asset-collections'],
        }),
        emptyAssetCollectionsTrash: builder.mutation<null, EmptyAssetCollectionsTrashRequest>({
            query: ({ projectId }) => ({
                url: `developer/projects/${projectId}/assetCollections-trash`,
                method: 'DELETE',
            }),
            invalidatesTags: ['recent-trashed-folders'],
        }),
        getSharedAssetCollection: builder.query<AssetCollection, GetSharedAssetCollectionRequest>({
            query: ({ code }) => ({
                url: `shared/assetCollections/${code}`,
                method: 'GET',
            }),
        }),
        sharedAssetCollectionGrant: builder.mutation<SharedResourceGrant, SharedAssetCollectionGrantRequest>({
            query: ({ code, password, name }) => ({
                url: `shared/assetCollections/${code}/grant`,
                method: 'POST',
                body: { password, name },
            }),
        }),
        sharedAssetCollectionViewed: builder.mutation<null, SharedAssetCollectionViewedRequest>({
            query: ({ code }) => ({
                url: `shared/assetCollections/${code}/viewed`,
                method: 'POST',
            }),
        }),
        getSharedAssetCollectionMembersForMentions: builder.query<
            MentionDict[],
            GetSharedAssetCollectionMembersRequest
        >({
            query: ({ code }) => ({
                url: `shared/assetCollections/${code}/membersToMention`,
                method: 'GET',
            }),
            providesTags: ['project-members-to-mention'],
            transformResponse: (response: User[]) =>
                response.map((user): MentionDict => ({ id: user.id, display: user.name })),
        }),
    }),
});

export default assetCollectionApi;

export const {
    useGetRecentAssetCollectionsQuery,
    useGetRecentTrashedAssetCollectionsQuery,
    useGetAssetCollectionsMutation,
    useGetAllAssetCollectionsQuery,
    useGetAvailableAssetCollectionsMutation,
    useGetAssetCollectionQuery,
    useStoreAssetCollectionMutation,
    useUpdateAssetCollectionMutation,
    useStoreAssetCollectionThumbnailMutation,
    useArchiveAssetCollectionMutation,
    useArchiveAssetCollectionsMutation,
    usePurgeAssetCollectionMutation,
    useRestoreAssetCollectionMutation,
    useRestoreAssetCollectionsMutation,
    useEmptyAssetCollectionsTrashMutation,
    useGetSharedAssetCollectionQuery,
    useSharedAssetCollectionGrantMutation,
    useSharedAssetCollectionViewedMutation,
    useGetSharedAssetCollectionMembersForMentionsQuery,
} = assetCollectionApi;
